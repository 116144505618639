// extracted by mini-css-extract-plugin
export var hero = "faq-module--hero--1Mylo";
export var history = "faq-module--history--PZFly";
export var manifest = "faq-module--manifest--xz3OT";
export var practice = "faq-module--practice--9Q6C8";
export var firstImage = "faq-module--firstImage--yWaMw";
export var lastImage = "faq-module--lastImage--blAK0";
export var videoContainer = "faq-module--videoContainer--Ct+xI";
export var videoContent = "faq-module--videoContent--aWlIK";
export var bridgeCover = "faq-module--bridgeCover--Rl3oj";
export var bridgeImage = "faq-module--bridgeImage--ULpbE";
export var historyTwo = "faq-module--historyTwo--YIaSW";
export var info = "faq-module--info---tU-c";
export var technologies = "faq-module--technologies--YPHLe";
export var slide = "faq-module--slide--9j5Cg";
export var dots = "faq-module--dots--BMZ00";
export var practiceWrapper = "faq-module--practiceWrapper--6lOJN";
export var blog = "faq-module--blog--v0hTx";
export var tab1 = "faq-module--tab1--XNiJF";
export var tab2 = "faq-module--tab2--U7lLY";
export var tab3 = "faq-module--tab3--3xVet";
export var tab4 = "faq-module--tab4--l4fft";
export var practiceContainer = "faq-module--practiceContainer--8VEIM";
export var arrow = "faq-module--arrow--DlEhx";
export var core = "faq-module--core--k0PO0";
export var search = "faq-module--search--x9t4O";
export var searchContainer = "faq-module--searchContainer--4tiQX";
export var open = "faq-module--open--2vito";
export var questions = "faq-module--questions--zbNW1";
export var active = "faq-module--active---irb-";