import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { scroller } from 'react-scroll'
import { Head, Menubar } from '@components'
import cn from 'classnames'
import * as st from '@assets/styl/faq.module.styl'
import { useLanguage } from '../../hooks'
import { translatePath } from '../../translations'
import search from '@assets/svg/search.svg'

const normalize = (str: string) =>
  str
    .toUpperCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

const filterSearch = (str: string, query: string) => {
  str = normalize(str)
  query = normalize(query)
  let include = true
  query
    .split(' ')
    .forEach((query) => (include = include && str.includes(query)))
  return include
}

export default () => {
  const questions = useStaticQuery(graphql`
    query {
      allQuestions {
        nodes {
          title
          answer
          theme
        }
      }
    }
  `).allQuestions.nodes

  const [query, setQuery] = useState('')
  const [searchOpen1, setSearchOpen1] = useState(false)
  const [searchOpen2, setSearchOpen2] = useState(false)

  const themes = []
  for (const q of questions) if (!themes.includes(q.theme)) themes.push(q.theme)
  const [themeActive, setThemeActive] = useState(0)

  const defQuestionActive: any = {}
  for (const theme of themes) defQuestionActive[theme] = -1
  const [questionActive, setQuestionActive] = useState(defQuestionActive)
  const { t } = useLanguage()

  return (
    <>
      <Head
        pathname={translatePath('/contato/faq/')}
        title={t('FAQ.titlePage')}
        description={t('FAQ.descriptionPage')}
      />

      <Menubar pathname="/contato/faq/" position="absolute" />

      <section className={cn(st.hero, 'heroScroll')}>
        <h1>
          {t('FAQ.title.1')} <b>{t('FAQ.title.2')}</b>
        </h1>
      </section>

      <section className={st.core}>
        <div>
          <h2>{t('FAQ.faq')}</h2>

          <div className={st.search} onMouseLeave={() => setSearchOpen2(false)}>
            <label htmlFor="faq-busca">{t('FAQ.write')}</label>
            <div className={st.searchContainer}>
              <input
                type="text"
                id="faq-busca"
                placeholder="Qual sua dúvida?"
                onFocus={() => {
                  setSearchOpen1(true)
                  setSearchOpen2(true)
                }}
                onBlur={() => setSearchOpen1(false)}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <img src={search} alt="Search icon" />
              <ul
                className={cn(Boolean(searchOpen1 || searchOpen2) && st.open)}
              >
                {query &&
                  questions
                    .filter((q) => filterSearch(q.title, query))
                    .map((question, key) => (
                      <li
                        key={key}
                        className={st.teste}
                        onClick={() => {
                          setQuery(question.title)
                          setThemeActive(themes.indexOf(question.theme))
                          const copy = { ...questionActive }
                          copy[question.theme] = questions
                            .filter((q) => q.theme === question.theme)
                            .indexOf(question)
                          setQuestionActive(copy)
                          setSearchOpen2(false)
                          setTimeout(
                            () =>
                              scroller.scrollTo(question.title, {
                                duration: 300,
                                smooth: true,
                                offset: -200,
                              }),
                            300
                          )
                          
                        }}
                      >
                        {question.title}
                      </li>
                    ))}
              </ul>
            </div>
          </div>

          <div className={st.questions}>
            <div>
              <div>
                <h4>{t('FAQ.filterForTheme')}</h4>
                <ul>
                  {themes.map((theme, key) => (
                    <li
                      key={key}
                      className={cn(key === themeActive && st.active)}
                      onClick={() => setThemeActive(key)}
                    >
                      <span>{theme}</span>
                    </li>
                  ))}
                  <li
                    // style={{ top: `${(themeActive / themes.length) * 100}%` }}
                  >
                    <span style={{ top: `${(themeActive / themes.length) * 100}%` }}></span>
                  </li>
                </ul>
              </div>
            </div>

            <div>
              {themes.map((theme, key) => (
                <ul key={key} className={cn(key === themeActive && st.active)}>
                  {questions
                    .filter((q) => q.theme === theme)
                    .map((question, key) => (
                      <li
                        key={key}
                        className={cn(
                          key === questionActive[theme] && st.active
                        )}
                        onClick={() => {
                          const copy = { ...questionActive }
                          copy[theme] = key
                          setQuestionActive(copy)
                        }}
                        id={question.title}
                      >
                        <h5>{question.title}</h5>
                        <div
                          dangerouslySetInnerHTML={{ __html: question.answer }}
                        ></div>
                      </li>
                    ))}
                </ul>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
